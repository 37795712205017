import {Injectable} from "@angular/core";
import {ModalType} from "../../enums/global/modal-type.enum";
import {IconName} from "../../enums/global/icon-name.enum";
import {InfoCardConfigModel, InfoCardTextConfig} from "../../models/global/info-card-config.model";
import {Person} from "../../models/person.model";
import {AvatarUser} from "../../components/avatar-component/models/avatar-user.model";
import {FormGroup} from "@angular/forms";
import moment from "moment";
import CryptoJS from "crypto-js";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
/**
 * The UtilsService provides utility methods that are used globally throughout the application.
 */
export class UtilsService {

  constructor(private readonly router: Router) {
  }

  /**
   * Returns the CSS class for the modal icon based on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  cssClass(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return 'featured-icon-primary-xl';
      case ModalType.ERROR:
        return 'featured-icon-error-xl';
      case ModalType.WARNING:
        return 'featured-icon-warning-xl';
      case ModalType.SECONDARY:
        return 'featured-icon-gray-xl';
      default:
        return 'featured-icon-primary-xl';
    }
  }

  /**
   * Returns the CSS class for the confirmation button based on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  confirmButtonClass(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return 'btn-primary';
      case ModalType.ERROR:
        return 'btn-danger';
      case ModalType.WARNING:
        return 'btn-primary';
      default:
        return 'btn-primary';
    }
  }

  /**
   * Returns icon name to use in the modal on the given model type.
   * @param modalType modal type {@link ModalType}
   */
  modalIconName(modalType: ModalType) {
    switch (modalType){
      case ModalType.SUCCESS:
        return IconName.CHECKED_CIRCLE;
      case ModalType.ERROR:
        return IconName.ALERT_CIRCLE;
      case ModalType.WARNING:
        return IconName.ALERT_TRIANGLE;
      default:
        return IconName.CHECKED_CIRCLE;
    }
  }

  /**
   * Builds and returns the config of awaiting GDPR preferences card info.
   * Displayed when a contact has an ongoing GDPR request or no requests at all.
   */
  getWaitingGDPRRequestCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'Awaiting GDPR Preferences',
        '20px'
      ),
      new InfoCardTextConfig(
        `We're waiting to receive this contact's GDPR preferences.
       <br>Once the contact submits their preferences, the information will be updated here.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  /**
   * Builds and returns the config of No GDPR Preferences card info.
   * Displayed when a contact has an ongoing GDPR request or no requests at all.
   */
  getGDPRRequestCardConfig() {
    return new InfoCardConfigModel(
      ModalType.SECONDARY,
      new InfoCardTextConfig(
        'No GDPR Preferences',
        '20px'
      ),
      new InfoCardTextConfig(
        `No GDPR consent request has been sent yet.
       <br>Once a request is made and preferences are submitted by the contact,the information will be updated here.`,
        '12px'
      ),
      '400px',
      IconName.GDPR
    );
  }

  createAvatarUsers(persons: Person[], check: boolean = false): AvatarUser[] {
    return persons.map((person: Person) => new AvatarUser(person, person.role, check));
  }

  /**
   * This method checks if a form control identified by the specified name
   * within the specified form group has errors of the specified validation type.
   * @param formGroup       form group {@link FormGroup}
   * @param controlName     form control name (ex: firstName,...)
   * @param validationType  validation applied to form control (ex: required,...)
   */
  isControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    let control = formGroup.get(controlName);
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }


  /**
   * Adapts the given date to UTC timezone.
   *
   * @param date - The date to be adapted. It should be a moment.js Moment object.
   *
   * @returns The adapted date in UTC timezone. If the input date is null or undefined,
   *          the function will return null.
   */
  adaptUtcTimeZone(date: moment.Moment) {
    if (date) {
      // Convert the date to UTC without modifying the original date object
      return date.utc(true);
    } else {
      // Return null if the provided date is invalid
      return null;
    }
  }

  /**
   * Determines if the given text will overflow a container with a specified width in rem.
   *
   * @param remWidth - The width of the container in rem.
   * @param charCount - The number of characters in the text.
   * @returns `true` if the text overflows the container, otherwise `false`.
   */
  doesTextOverflow(remWidth: number, charCount: number): boolean {
    // Convert the container width from rem to pixels, assuming the root font size is 12px.
    const divWidth = remWidth * 12;

    // Estimate the average width of a character in pixels.
    // Assuming a character width ratio of 0.50 for the given font size.
    const charWidth = 12 * 0.50;

    // Calculate the total width of the text in pixels.
    const totalTextWidth = charWidth * charCount;

    // Compare the total text width with the container width.
    // Return true if the text width exceeds the container width (overflow).
    return totalTextWidth > divWidth;
  }

  /**
   * Prepares a URL link for a specific entity by encoding its ID and
   * generating a URL with said ID as a query parameter.
   *
   * This generated URL can be used to navigate to the specified target route,
   * with the side panel or other contextual information preloaded for that entity.
   *
   * @param entityId The ID of the entity to be encoded and used in the URL.
   * @param targetRoute The target route where the URL should navigate to (ex: 'contacts/all').
   * @returns A string representing the full URL, including the base origin and query parameters.
   */
  prepareEntityLink(entityId: number, targetRoute: string) {
    // Step 1: Encode the entity ID using Base64url encoding.
    const encodedEntityId = CryptoJS.enc.Base64url.stringify(
      CryptoJS.enc.Utf8.parse(entityId.toString())
    );

    // Step 2: Create a URL tree for the target route with the encoded entity ID as a query parameter.
    const urlTree = this.router.createUrlTree([targetRoute], {
      queryParams: { key: encodedEntityId },
    });

    // Step 3: Serialize the URL tree into a string, forming the full URL with base origin and query parameter.
    return window.location.origin + this.router.serializeUrl(urlTree);
  }

}
